import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import _ from 'lodash';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ConversationsList from './ConversationsList';
import Messages from './Messages';
import Loading from './Loading';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { integration, retrieveConversations, retrieveGroups } from './data';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    // backgroundColor: '#444753', // theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  add: {
    flex: 1,
    textAlign: 'right',
    padding: 10,
  },
  logo: {
    paddingRight: 100,
  },
  switchContainer: {
    marginTop: 'auto',
  },
}));

const createConversations = (contact, conversations = [], groups = []) => {
  const result = _.concat([], conversations);

  groups
    .filter(
      (group) =>
        !conversations.find(
          (convo) =>
            (convo.group ? convo.group.id : 'default') === group.id &&
            moment().isSameOrBefore(convo.closed),
        ),
    )
    .forEach((group) => {
      const conversation = {
        group,
        contact,
      };

      result.push(conversation);
    });

  return result;
};

const ConversationsView = ({ accountId, contact, readonly }) => {
  const classes = useStyles();

  const [conversations, setConversations] = useState([]);
  const [conversation, setConversation] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [groups, setGroups] = useState([]);
  const [closed, setClosed] = useState(false);
  // const [templastes, setTemplates] = useState([]);

  // console.log('setConversations, setConversations', setConversations);
  useEffect(() => {
    retrieveGroups().then((data) => {
      setGroups(data);
    });
  }, [accountId]);

  useEffect(() => {
    retrieveConversations({ closed })
      .then((cvs) => createConversations(contact, cvs, groups))
      .then((cvs) => {
        // retain selected conversation when reloading conversations
        let idx = 0;
        if (conversation) {
          idx = conversation.id
            ? _.findIndex(cvs, (conv) => conv.id === conversation.id)
            : _.findIndex(
                cvs,
                (conv) => !conv.id && conv.group.id === conversation.group.id,
              );

          if (idx < 0) {
            idx = 0;
          }
        }

        setConversations(cvs);
        setSelectedIndex(idx);
        setConversation(cvs[idx]);
      });
  }, [contact, contact.id, groups, closed]);

  const handleSelectConversation = (conv, index) => {
    setConversation(conv);
    setSelectedIndex(index);
  };

  if (!conversations) {
    return <Loading label="Loading Conversations..." />;
  }

  // console.log('data', contact, conversations);
  return (
    <div className={classes.root}>
      <CssBaseline />
      {integration === 'pipedrive' ? (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <img
              src="https://hub.sakari.io/images/sakari_logo_w.svg"
              height={40}
              className={classes.logo}
            />
            <Typography variant="h6" noWrap>
              Conversations with {contact.firstName} {contact.lastName}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : (
        ''
      )}

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {integration === 'pipedrive' ? <Toolbar /> : ''}

        <ConversationsList
          contact={contact}
          conversations={conversations}
          selectedIndex={selectedIndex}
          onChange={handleSelectConversation}
        />
        <List className={classes.switchContainer}>
          <ListItem>
            <ListItemText>Closed</ListItemText>

            <ListItemSecondaryAction>
              <Tooltip title="Show Closed Conversations">
                <Switch
                  value={closed}
                  onChange={(evt, val) => setClosed(val)}
                  id="closed-switch"
                />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Drawer>
      {conversation ? (
        <main className={classes.content}>
          {integration === 'pipedrive' ? <Toolbar /> : ''}
          {/* <div className={classes.toolbar}/> */}
          <Messages
            accountId={accountId}
            conversation={conversation}
            readonly={readonly}
          />
        </main>
      ) : null}
    </div>
  );
};

export default ConversationsView;
