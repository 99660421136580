import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import swal from '@sweetalert/with-react';
import Handlebars from 'handlebars/dist/handlebars';
import moment from 'moment-timezone';
import Switch from '@material-ui/core/Switch';
import _ from 'lodash';
import { DateTimePicker } from '@material-ui/pickers';

import {
  sendMessage,
  removeTemplate,
  saveTemplate,
  retrieveTemplates,
  retrieveIntegrationContact,
  id,
  integration,
  retrieveDeal,
} from './data';
import SaveTemplateDialog from './SaveTemplateDialog';
import UploadFileDialog from './UploadFileDialog';

// TODO consolidate with sakari-data
Handlebars.registerHelper(
  'formatDate',
  function (dt, format, timezone = 'Universal', ctxt) {
    // console.log('formatDate', dt, format, timezone, ctxt);
    if (!ctxt) {
      ctxt = timezone;
      timezone = 'Universal';
    }

    if (`${dt}`.match(/^\d{10}$/)) {
      //unix timestamp
      return moment(Number(dt) * 1000)
        .tz(timezone)
        .format(format);
    } else if (`${dt}`.match(/^\d{13}$/)) {
      //timestamp in milliseconds
      return moment(Number(dt)).tz(timezone).format(format);
    } else if (`${dt}`.match(/^NOW$/i)) {
      //use current date
      return moment().tz(timezone).format(format);
    } else {
      return moment(dt).tz(timezone).format(format);
    }
  },
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    verticalAlign: 'center',
  },
  templates: {
    flex: 1,
  },
  textField: {
    flex: 1,
  },
  fab: {
    margin: theme.spacing(1),
  },
  buttons: {
    // paddingTop: 17,
    alignItems: 'center',
    verticalAlign: 'center',
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  chip: {},
}));

const enrichMessage = (message, wildcards, deal) => {
  // console.log('contact wildcards: ', wildcards);
  try {
    const combineWildcards = _.assign({}, wildcards, deal);
    const template = Handlebars.compile(message);
    return Promise.resolve(template(combineWildcards));
  } catch (err) {
    console.error('enrichMessage-error', err);
    return Promise.reject(new Error('Unable to parse message'));
  }
};

export default ({ accountId, conversation }) => {
  const classes = useStyles();

  const [templates, setTemplates] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [template, setTemplate] = useState('');
  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState(false);
  const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [sending, setSending] = useState(false);
  const [integrationContact, setIntegrationContact] = useState();
  const [deal, setDeal] = useState();
  const [sendAt, setSendAt] = useState(null);
  const noTemplateItem = { id: '0', name: 'No templates' };
  useEffect(() => {
    reloadTemplates();
    retrieveDeal().then((deal) => setDeal(deal));
    retrieveIntegrationContact(id).then((contact) =>
      setIntegrationContact(contact),
    );
  }, [accountId]);

  const reloadTemplates = () => {
    retrieveTemplates().then((data) => {
      setTemplates(data);

      if (data.length === 0) {
        setTemplate(noTemplateItem.id);
      }
    });
  };

  const loadTemplate = (evt) => {
    // console.log('evt2', evt.target.value);
    const template = templates.find((t) => t.id === evt.target.value);
    setTemplate(template);
    setMessage(template.template);
    setShowTemplates(false);
  };

  const showSaveTemplate = () => {
    setShowSaveTemplateDialog(true);
  };

  const handleSaveTemplate = ({ name, pvt }) => {
    if (!name) {
      return setShowSaveTemplateDialog(false);
    }

    saveTemplate({
      id: template ? template.id : null,
      name,
      pvt,
      template: message,
    })
      .then((res) => {
        // console.log('save template res', res);
        setShowSaveTemplateDialog(false);
        reloadTemplates();
      })
      .catch((err) => {
        return swal({
          title: 'Unable to save template',
          text: 'An error occurred while trying to create your template. Please contact help@sakari.io if the issue persists',
          icon: 'warning',
        });
      });
  };

  const handleUploadFile = (file) => {
    setShowUploadFileDialog(false);
    setFile(file);
  };

  const templateChanged = (template) => {
    setMessage(template);
    setTemplate(null);
  };

  const confirmDeleteTemplate = () => {
    return swal({
      title: 'Are you sure?',
      text: 'This template may be shared across other members of your team.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // console.log('delete template', template);
        return removeTemplate(template.id).then(() => {
          reloadTemplates();
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      {showTemplates ? (
        <div className={classes.container}>
          <FormControl
            variant="outlined"
            className={classes.templates}
            fullWidth
          >
            <InputLabel id="demo-simple-select-outlined-label" shrink>
              Template
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={template ? template.id : ''}
              onChange={loadTemplate}
              //disabled={(templates || []).length === 0}
              fullWidth
            >
              {(templates || [noTemplateItem]).map((template) => (
                <MenuItem value={template.id}>{template.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={classes.buttons}>
            {template && template.template === message ? (
              <Fab
                color="secondary"
                size="small"
                aria-label="delete"
                className={classes.fab}
                onClick={confirmDeleteTemplate}
              >
                <DeleteIcon />
              </Fab>
            ) : null}
            {message && (!template || template.template !== message) ? (
              <Fab
                color="primary"
                size="small"
                aria-label="save"
                className={classes.fab}
                onClick={showSaveTemplate}
              >
                <SaveIcon />
              </Fab>
            ) : null}
          </div>
        </div>
      ) : null}

      <div className={classes.container}>
        <div className={classes.buttons}>
          {/*<Fab color="default" aria-label="add" className={classes.fab}>*/}
          {/*<AddPhotoIcon/>*/}
          {/*</Fab>*/}
          <Tooltip title={'Add Attachment'}>
            <Fab
              color={'primary'}
              aria-label="add"
              size="small"
              className={classes.fab}
              onClick={() => setShowUploadFileDialog(!showUploadFileDialog)}
            >
              <AttachmentIcon />
            </Fab>
          </Tooltip>
          <Tooltip title={showTemplates ? 'Hide Templates' : 'Show Templates'}>
            <Fab
              color={showTemplates ? 'secondary' : 'primary'}
              aria-label="add"
              size="small"
              className={classes.fab}
              onClick={() => setShowTemplates(!showTemplates)}
            >
              <BookmarksIcon />
            </Fab>
          </Tooltip>
        </div>

        <div className={classes.messageContent}>
          <TextField
            id="outlined-multiline-flexible"
            label="Message"
            placeholder="Type your message..."
            multiline
            rows={2}
            rowsMax="4"
            value={message}
            onChange={(evt) => templateChanged(evt.target.value)}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div>
            {file ? (
              <Chip
                className={classes.chip}
                icon={<AttachmentIcon />}
                label={
                  file.name.length > 20
                    ? file.name.substring(0, 20) + '...'
                    : file.name
                }
                onDelete={() => setFile(null)}
              />
            ) : null}
          </div>
        </div>
        <div className={classes.buttons}>
          {/*<Fab color="default" aria-label="add" className={classes.fab}>*/}
          {/*<AddPhotoIcon/>*/}
          {/*</Fab>*/}
          <Tooltip title="Send">
            <Fab
              color="primary"
              aria-label="add"
              className={classes.fab}
              disabled={sending}
              onClick={() => {
                setSending(true);
                const msg = message;
                // console.log('file2', file);
                enrichMessage(message, integrationContact, deal)
                  .then((processedTemplate) =>
                    sendMessage(conversation, processedTemplate, file, sendAt),
                  )
                  .then((result) => {
                    // console.log('result', result);
                    setMessage('');
                    if (integration === 'pipedrive') {
                      // if integration is pipedrive, close window
                      window.close();
                    } else {
                      window.parent.postMessage(
                        JSON.stringify({ action: 'DONE' }),
                        '*',
                      );
                    }
                  })
                  .catch((err) => {
                    console.error(
                      'send message error2',
                      err,
                      err.response ? err.response.data : '',
                    );
                    let title = err.message || 'Unable to send message';
                    if (
                      err.response &&
                      err.response.data &&
                      err.response.data.error &&
                      err.response.data.error.message
                    ) {
                      title = err.response.data.error.message;
                    }

                    swal({
                      title,
                      text: 'An error occurred while trying to send your message. Please contact help@sakari.io if the issue persists',
                      icon: 'warning',
                    });
                    setSending(false);
                    setMessage(msg);
                  });
              }}
            >
              <SendIcon />
            </Fab>
          </Tooltip>
        </div>
      </div>

      {/* <div className={classes.container}>
        <Switch
          onChange={() => setSendAt(sendAt ? null : new Date())}
          checked={!!sendAt}
          color="primary"
        />
        <div style={{ paddingRight: '10px' }}>Send later?</div>

        {sendAt ? (
          <Tooltip title="Message to be sent at">
            <DateTimePicker onChange={setSendAt} value={sendAt} />
          </Tooltip>
        ) : null}
      </div> */}

      <SaveTemplateDialog
        open={showSaveTemplateDialog}
        onClose={handleSaveTemplate}
      />

      <UploadFileDialog
        open={showUploadFileDialog}
        onClose={handleUploadFile}
      />
    </div>
  );
};
