import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import Media from './Media';

const sentBy = (message) => {
  if (message.created && message.created.by && message.created.by.name) {
    return message.created.by.name;
  }

  return 'Unknown';
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'list-item',
  },

  message: {
    color: 'white',
    padding: '18px 20px',
    lineHeight: '26px',
    fontSize: '16px',
    borderRadius: '7px',
    marginBottom: '10px',
    position: 'relative',
    overflowWrap: 'anywhere',
    display: 'flex',
    flexDirection: 'column',
  },
  messageData: {
    marginBottom: '10px',
  },

  messageDataTime: {
    color: 'grey', //lighten($gray, 8%);
    paddingLeft: '6px',
  },

  messageOutbound: {
    backgroundColor: '#86BB71',
  },

  messageInbound: {
    backgroundColor: '#94C2ED',
  },

  alignLeft: {
    textAlign: 'left',
  },

  alignRight: {
    textAlign: 'right',
  },

  floatLeft: {
    float: 'left',
  },

  floatRight: {
    float: 'right',
  },

  clearfix: {
    visibility: 'hidden',
    display: 'block',
    fontSize: 0,
    content: '',
    clear: 'both',
    height: 0,
  },
}));

export default ({ message }) => {
  const classes = useStyles();
  //<!--align-right-->
  return (
    <ListItem className={classes.listItem}>
      <div
        className={`${
          message.outgoing ? classes.floatRight : classes.floatLeft
        }`}
      >
        <div
          className={`${classes.messageData} ${
            message.outgoing ? classes.alignRight : classes.alignLeft
          }`}
        >
          <span className={classes.messageDataTime}>
            {moment(message.created.at).calendar()}
          </span>{' '}
          &nbsp; &nbsp;
          <span>{sentBy(message)}</span>
        </div>
        <div
          className={`${classes.message} ${
            message.outgoing ? classes.messageOutbound : classes.messageInbound
          } ${message.outgoing ? classes.alignRight : classes.alignLeft} `}
        >
          {message.message || message.template}

          {(message.media || []).map((media, idx) => {
            // if (media.type && media.type.indexOf('image/') === 0) {
            return <Media key={idx} message={message} idx={idx} />;
            // }

            // return null;
          })}
        </div>
      </div>
      <div className={classes.clearfix}></div>
    </ListItem>
  );
};
