import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  switchLabel: {
    margin: 0,
  },
  switchContainer: {
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
  },
});

function SaveTemplateDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;

  const [name, setName] = useState('');
  const [pvt, setPrivate] = useState(false);

  const handleClose = (evt) => {
    // console.log(evt);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Save Template"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="simple-dialog-title">Save Template</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Provide a name for the template for future reference
        </DialogContentText>
        <TextField
          autoFocus
          variant="outlined"
          label="Name"
          placeholder="Template name"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
        <div className={classes.switchContainer}>
          <DialogContentText className={classes.switchLabel}>
            Private
          </DialogContentText>
          <Switch
            checked={pvt}
            onChange={(evt) => setPrivate(evt.target.checked)}
            color="primary"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onClose({ name, pvt })} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SaveTemplateDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SaveTemplateDialog;
