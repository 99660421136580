import { Buffer } from 'buffer';
import axios from 'axios';
import _ from 'lodash';
import { saveAs } from 'file-saver';

import { BASE_URL } from '../config';

var urlParams = new URLSearchParams(window.location.search);

// console.log(urlParams.has('post')); // true
const token = urlParams.get('token');
const accountId = urlParams.get('accountId');
const contactId = urlParams.get('cid');
const readonly = urlParams.get('readonly');
const dealId = urlParams.get('did');

const id = urlParams.get('vid') || urlParams.get('id'); // either vid (hubspot) or id (pipedrive)
const integration = urlParams.get('integration');
// console.log('token', token);
// console.log('accountId', accountId);

const retrieveContact = async () => {
  const resp = await axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/contacts/${contactId}`,
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
      Origin: 'https://hub.sakari.io',
      // Host: 'https://hub.sakari.io',
    },
  });
  return resp.data.data;
};

const retrieveDeal = async () => {
  const integrationType = integration === 'pipedrive' ? 'pipedrive' : 'hubspot';

  if ('hubspot' && dealId) {
    const resp = await axios({
      url: `${BASE_URL}/v1/accounts/${accountId}/integrations/${integrationType}/deals/${dealId}`,
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
    return resp.data.data;
  }
  return {};
};

// const createContact = ({firstName, lastName, email, mobile, phone}) => {
//   return axios({
//     url: `${BASE_URL}/v1/accounts/${accountId}/contacts/${contactId}`,
//     method: 'GET',
//     headers: {
//       Authorization: `bearer ${token}`,
//       // Origin: 'https://hub.sakari.io',
//       // Host: 'https://hub.sakari.io',
//     },
//   }).then((resp) => resp.data.data);
// };

const retrieveConversations = ({ closed }) => {
  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/conversations?contactId=${contactId}&closed=${closed}`,
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
      // Origin: 'https://integrations.sakari.io',
    },
  }).then((resp) => resp.data.data);
};

const retrieveMessages = (conversationId) => {
  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/messages?conversationId=${conversationId}&orderBy=createdAt-desc&limit=100`,
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
      // Origin: 'https://integrations.sakari.io',
    },
  }).then((resp) => _.reverse(resp.data.data));
};

const retrieveGroups = () => {
  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/groups?limit=100`,
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
      // Origin: 'https://integrations.sakari.io',
    },
  })
    .then((resp) => resp.data.data)
    .then((groups) => {
      if (groups && groups.length) {
        return groups;
      }

      return [
        {
          id: 'default',
          name: 'Default',
        },
      ];
    });
};

const retrieveTemplates = () => {
  // account for type = pipedrive

  const type = integration === 'pipedrive' ? 'pipedrive' : 'hubspot';

  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/templates?type=${type}&limit=200&orderBy=name-asc`,
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
      // Origin: 'https://integrations.sakari.io',
    },
  }).then((resp) => resp.data.data);
};

const saveTemplate = ({ id, name, template, pvt }) => {
  // set template type based on integration value

  const type = integration === 'pipedrive' ? 'pipedrive' : 'hubspot';

  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/templates`,
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
      // Origin: 'https://integrations.sakari.io',
    },
    data: {
      name,
      private: pvt,
      type,
      template,
    },
  }).then((resp) => resp.data.data);
};

const removeTemplate = (templateId) => {
  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/templates/${templateId}`,
    method: 'DELETE',
    headers: {
      Authorization: `bearer ${token}`,
      // Origin: 'https://integrations.sakari.io',
    },
  }).then((resp) => resp.data.data);
};

const retrieveIntegrationContact = (id) => {
  // rename retrieveHubspotContact as retrieveIntgrationContact

  const integrationType = integration === 'pipedrive' ? 'pipedrive' : 'hubspot';
  const integrationRoute = integration === 'pipedrive' ? 'persons' : 'contacts';

  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/integrations/${integrationType}/${integrationRoute}/${id}`,
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
    },
  }).then((resp) => resp.data.data);
};

const sendMessage = (conversation, message, file, sendAt) => {
  const media = file ? [{ url: file.link, type: file.type }] : null;
  const data = conversation.id
    ? {
        conversations: [conversation.id],
        template: message,
        media,
        ...(sendAt && { sendAt }),
      }
    : {
        contacts: [conversation.contact],
        template: message,
        phoneNumberFilter:
          conversation.group && conversation.group.id !== 'default'
            ? {
                group: conversation.group,
              }
            : null,
        media,
        ...(sendAt && { sendAt }),
      };

  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/messages`,
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
      // Origin: 'https://integrations.sakari.io',
    },
    data,
  }).then((resp) => resp.data.data);
};

// const sendGroupMessage = (accountId, contactId, groupId, message) => {
//   return axios({
//     url: `${BASE_URL}/v1/accounts/${accountId}/messages`,
//     method: 'POST',
//     headers: {
//       Authorization: `bearer ${token}`,
//       // Origin: 'https://integrations.sakari.io',
//     },
//     data: {
//       contacts: [{ id: contactId }],
//       template: message,
//       phoneNumberFilter: {
//         group: {
//           id: groupId,
//         },
//       },
//     }
//   }).then((resp) => resp.data.data);
// };

const uploadFile = (file) => {
  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/tools/sharefile`,
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': file.type,
    },
    data: file,
  }).then((resp) => {
    // console.log('resp', resp.data);
    return {
      name: file.name,
      link: resp.data.data.link,
      type: file.type,
    };
  });
};

const loadImage = (messageId, media, idx) => {
  return axios({
    url: `${BASE_URL}/v1/accounts/${accountId}/messages/${messageId}/media/${idx}`,
    method: 'GET',
    headers: {
      Authorization: `bearer ${token}`,
    },
    responseType: 'arraybuffer',
  }).then((resp) => {
    return Buffer.from(resp.data, 'binary').toString('base64');
    // }).catch(err => {
    //   console.error('Unable to retrieve image', err);
  });
};

const download = (item) => {
  // console.log('item', item);
  return axios({
    url: item.url,
    headers: {
      Authorization: `bearer ${token}`,
    },
    raw: true,
    responseType: 'blob',
  }).then((res) => {
    // return res.blob()
    //   .then((blob) => {
    // console.log('filename', item.filename);
    saveAs(res.data, item.filename);
    // })
  });
};

export {
  accountId,
  contactId,
  id,
  integration,
  readonly,
  retrieveContact,
  retrieveConversations,
  retrieveMessages,
  retrieveGroups,
  retrieveDeal,
  retrieveTemplates,
  saveTemplate,
  removeTemplate,
  retrieveIntegrationContact,
  sendMessage,
  uploadFile,
  loadImage,
  download,
};
