import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './App.css';
import Chat from './chat';
import { PRIMARY, SECONDARY } from './colors';
import swal from 'sweetalert';
import jwtDecode from 'jwt-decode';

const theme = createTheme({
  palette: {
    background: {
      default: '#fff',
      paper: '#fff',
    },
    primary: {
      main: PRIMARY, //header background
      contrastText: '#fff',
    },
    secondary: {
      main: SECONDARY, //button color
      contrastText: '#fff',
    },
    error: {
      light: '#dc3545',
      main: '#dc3545',
      dark: '#dc3545',
      contrastText: '#3fb558',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)', //left navigation text
      secondary: 'rgba(0, 0, 0, 0.87)', //header grid text
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
  },
});

var urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

function App() {
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    let countdown = 900000;

    if (token) {
      let { exp: expiration } = jwtDecode(token);
      expiration *= 1000; // convert to milliseconds
      const startTime = Date.now();

      countdown = expiration - startTime;

      if (countdown <= 0) {
        setIsExpired(true);
        return;
      }
    }

    let timer = setTimeout(() => setIsExpired(true), countdown);
    return () => clearTimeout(timer);
  }, []);

  // if token expired, render alert
  if (isExpired) {
    swal({
      title: 'Session Expired',
      text: 'For security reasons your session has expired. Please refresh to continue',
      icon: 'warning',
      buttons: false,
      closeOnClickOutside: false,
    });
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/chat" element={<Chat />} />
            <Route path="/" element={<Chat />} />
          </Routes>
        </BrowserRouter>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
