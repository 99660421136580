import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';

import { loadImage, download } from './data';

const isImage = (item) => {
  // console.log('isImage', item);
  return item && item.type ? item.type.indexOf('image/') === 0 : false;
};

const renderDownload = (media) => {
  return (
    <div key={media.url}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => download(media)}
      >
        Download
      </Button>
    </div>
  );
};

export default ({ message, idx }) => {
  // console.log('media', message.media[idx]);
  useEffect(() => {
    if (isImage(message.media[idx])) {
      loadImage(message.id, message.media[idx], idx)
        .then((resp) => {
          const base64Flag = 'data:image/jpeg;base64,';
          document
            .getElementById(message.id + '-' + idx)
            .setAttribute('src', base64Flag + resp);
        })
        .catch((err) => console.error(err));
    } else {
    }
  }, [message.id]);

  return isImage(message.media[idx]) ? (
    <img id={`${message.id}-${idx}`} style={{ width: 200 }} width={200} />
  ) : (
    renderDownload(message.media[idx])
  );
};
