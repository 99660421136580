import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { blue } from '@material-ui/core/colors';
import Dropzone from 'react-dropzone';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import swal from '@sweetalert/with-react';

import { uploadFile } from './data';

import Loading from './Loading';
import { PRIMARY } from '../colors';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dropzone: {
    borderStyle: 'dashed',
    borderThickness: 3,
    borderColor: PRIMARY,
    borderRadius: 20,
    padding: 10,
  },
  dropzonePanel: {
    display: 'flex',
  },
  icon: {
    padding: 20,
    textAlign: 'center',
  },
});

function UploadFileDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const renderUpload = () => (
    <Dropzone
      multiple={false}
      onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
      accept={['image/png', 'image/jpg', 'image/*']}
    >
      {({ getRootProps, getInputProps }) => (
        <section className={classes.dropzone}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={classes.dropzonePanel}>
              <div className={classes.icon}>
                <UploadIcon fontSize="large" />
              </div>
              <div>
                <Typography variant="subtitle1">
                  Upload an image to attach to message
                </Typography>
                <Typography variant="caption">
                  Drag 'n' drop a file here, or click to select file
                </Typography>
              </div>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );

  const renderFile = (file) => {
    // console.log('file', file);
    return (
      <div>
        <div>Name: {file.name}</div>
        <div>Size: {file.size / 1000} kb</div>
        <div>Last Modified: {moment(file.lastModified).format('lll')}</div>
      </div>
    );
  };

  const renderContent = () => {
    if (uploading) {
      return <Loading label="Uploading..." />;
    }

    if (file) {
      return renderFile(file);
    }

    return renderUpload();
  };

  const handleClose = (file) => {
    if (file) {
      setUploading(true);
      return uploadFile(file)
        .then((result) => {
          // console.log('result', result);
          onClose(result);
          setFile(null);
          setUploading(false);
        })
        .catch((err) => {
          setFile(null);
          setUploading(false);
          return swal({
            title: 'Unable to upload file',
            text: err.message,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
          }).then(() => onClose());
        });
    } else {
      setFile(null);
      setUploading(false);
      onClose();
    }
  };

  return (
    <Dialog
      onClose={() => handleClose()}
      aria-labelledby="Upload File"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="simple-dialog-title">Upload File</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          color="primary"
          disabled={uploading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(file)}
          color="primary"
          disabled={uploading}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UploadFileDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UploadFileDialog;
