import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { PRIMARY } from '../colors';

const useStyles = makeStyles((theme) => ({
  text: {
    color: PRIMARY,
  },
}));

export default ({ conversation, selected, onClick }) => {
  const classes = useStyles();

  return (
    <ListItem button selected={selected} onClick={onClick}>
      <ListItemAvatar>
        <Avatar>
          {!conversation.closed ||
          moment().isSameOrBefore(conversation.closed) ? (
            <PersonIcon />
          ) : (
            <CloseIcon />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          conversation.group
            ? conversation.group.name
            : conversation.phoneNumber.number
        }
        secondary={
          conversation.lastMessage
            ? moment(conversation.lastMessage.created.at).fromNow()
            : null
        }
        className={classes.text}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete">
          <ChevronRightIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
