import Loader from 'react-loader-spinner';
import { PRIMARY } from '../colors';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    height: '100vh',
    flexDirection: 'column',
    // paddingTop
  },
}));

export default ({
  label,
  type = 'Circles',
  color = PRIMARY,
  height = 100,
  width = 100,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Loader type={type} color={color} height={height} width={width} />
      <Typography color="primary" variant="caption">
        {label}
      </Typography>
    </div>
  );
};
