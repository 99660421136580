import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import Conversation from './Conversation';

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'scroll',
  },
  heading: {
    backgroundColor: '#94C2ED',
    fontWeight: 'bold',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

export default ({ contact, conversations, selectedIndex, onChange }) => {
  const classes = useStyles();
  // console.log('conversations', conversations);

  return (
    <div className={classes.container}>
      <div className={classes.heading}>Send From Group</div>
      <List>
        {conversations.map((conversation, i) => (
          <Conversation
            key={conversation.id}
            conversation={conversation}
            selected={selectedIndex === i}
            onClick={() => onChange(conversation, i)}
          />
        ))}
      </List>
    </div>
  );
};
