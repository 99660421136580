import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Loading from './Loading';
import Message from './Message';
import CreateMessage from './CreateMessage';
import { retrieveMessages } from './data';
import PersonIcon from '@material-ui/icons/Person';
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  messages: {
    flex: 1,
    overflow: 'scroll',
    paddingBottom: 130,
  },
  createMessageContainer: {
    position: 'absolute',
    flex: 1,
  },
  createMessage: {
    bottom: 0,
    flex: 1,
  },
  emptyResult: {
    display: 'flex',
    height: '100%',
    alignSelf: 'center',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    flexDirection: 'column',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 250,
    right: 0,
    background: '#fff',
  },
}));

export default ({ accountId, conversation, templates, readonly }) => {
  const classes = useStyles();
  const [messages, setMessages] = useState();
  const scrollTargetElement = useRef();

  useEffect(() => {
    if (conversation.id) {
      // console.log('loading messages for ', conversation.id);
      retrieveMessages(conversation.id).then((data) => {
        setMessages(data);
        scrollTargetElement.current.scrollIntoView({ behavior: 'smooth' });
      });
    } else {
      setMessages([]);
    }
  }, [accountId, conversation.id]);
  // if (!conversation.id) {
  //   return (
  //     <div className={classes.container}>
  //       <div className={classes.emptyResult}>
  //         Sorry, you don’t have access to a group with phone numbers. Login to
  //         Sakari to change this.
  //       </div>
  //     </div>
  //   );
  // }

  if (!messages) {
    return <Loading label="Loading Messages..." />;
  }

  return (
    <div className={classes.container}>
      {/* {messages.length > 0 ? ( */}
        <>
          <div className={classes.messages}>
            <List className={classes.messagesList}>
              {messages.map((message) => (
                <Message key={message.id} message={message} />
              ))}
            </List>
            {/* <div ref={scrollTargetElement}>asdsadsadsdaddsddad</div> */}
          </div>
          <div className={classes.footer}>
            {!readonly &&
              (!conversation.closed ||
                moment().isSameOrBefore(conversation.closed)) && (
                <CreateMessage
                  accountId={accountId}
                  conversation={conversation}
                  templates={templates}
                  className={classes.createMessage2}
                />
              )}
          </div>
        </>
      {/* ) : (
        <div className={classes.emptyResult}>
          <PersonIcon></PersonIcon>
          <div>
            Nothing here yet! Send a message from one of your groups at left.
          </div>
        </div>
      )} */}
    </div>
  );
};
