import React, { useState, useEffect } from 'react';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import ConversationsView from './ConversationsView';

import { accountId, retrieveContact, readonly } from './data';
import Loading from './Loading';

const Chat = ({}) => {
  const [contact, setContact] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (accountId) {
      retrieveContact()
        .then((data) => {
          setContact(data);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data === 'Unauthorized'
          ) {
            setError({
              message:
                'Token has expired or user does not have access to this resource',
            });
            return;
          }

          setError(err);
        });
    }
  }, []);

  if (!accountId) {
    return <div>Invalid parameters</div>;
  }

  if (error) {
    return <div>ERROR: {error.message}</div>;
  }

  if (!contact) {
    return <Loading label="Loading..." />;
  }

  // console.log('contact', contact);
  return (
    <ConversationsView
      accountId={accountId}
      contact={contact}
      readonly={readonly}
    />
  );
};

export default Chat;

// class Chat extends React.Component {
//   state = {
//     accountId,
//     contact: null,
//   };
//
//   constructor() {
//     super();
//
//     retrieveContact(accountId, contactId)
//       .then((contact) => {
//         this.setState({ contact });
//       });
//   }
//
//   render() {
//
//   }
// }
//
// export default Chat;
