import {
  blue,
  cyan,
  deepOrange,
  green,
  grey,
  red,
  yellow,
} from '@material-ui/core/colors';

// const PRIMARY = '#328CC1'; //'#0B3C5D';
// const SECONDARY = '#D9B310';
const PRIMARY = '#2e7eed';
const SECONDARY = '#dc3545';
const TERTIARY = '#0B3C5D';

const QUEUED = '#D9B310';
const SENT = 'rgb(40, 167, 69)';

export {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  // cyan500 as PRIMARY,
  // deepOrange500 as SECONDARY,
  grey,
  cyan,
  deepOrange,
  green,
  red,
  yellow,
  blue,
  QUEUED,
  SENT,
};
